module.exports = {
  '您有提现正在确认中,请勿重复提交':'Your withdrawal is being confirmed, please do not submit it repeatedly.',
  '提现成功，等待区块确认':'Withdrawal successful, waiting for block confirmation',
  "竞猜下注统计到指定用户": "Guess betting statistics to specified users",
  "哈希竞猜退款": "Hash quiz refund",
  "第": "Section",
  "期": "stage",
  "未中奖": "Not winning the lottery",
  "已中奖": "Winning the lottery",
  "已撤单": "Cancelled order",
  "撤单申请已提交": "Cancellation application has been submitted",
  "投资已撤单或正在撤单": "Investment has been cancelled or is currently being cancelled",
  "认购成功": "Successful subscription",
  "复购成功": "Successful repurchase",
  "不在下注范围": "Not within the betting range",
  "当前无法参与竞猜,请联系管理员": "Currently unable to participate in the quiz, please contact the administrator",
  "请重新输入": "Please re-enter",
  "铸币数量太少": "The number of coins minted is too small",
  "不足": "Insufficient",
  "之间的金额": "Amount between",
  "不在投资范围": "Not within the scope of investment",
  "国库共识待发行量": "Treasury Consensus Pending Issuance",
  "强制卖出": "forced selling ",
  "哈希竞猜中奖": "Hash won in the guessing game",
  "用户主动撤单": "User actively cancels the order",
  "投资USDT": "Investing in USDT",
  "收益": "income",
  "充值": "Recharge",
  "提现": "Withdrawal",
  "兑换": "exchange",
  "增减": "Increase/decrease",
  "消费": "consumption",
  "退款": "refund",
  "总计": "total",
  "上拿": "Uptake",
  "直推": "Direct push",
  "间推": "Interpenetration",
  "新闻公告": "News Announcement",
  "详情": "details",
  "邀请": "Invite",
  "存入": "Deposit",
  "资产": "Asset",
  "转账": "Transfer",
  "当前价格": "Current price",
  "立即": "immediately",
  "认购": "subscription",
  "复购": "Repurchase",
  "消耗": "consume",
  "认购记录": "Subscription Record",
  "出局倍数": "Exit multiple",
  "倍": "times",
  "释放比例": "Release ratio",
  "数量": "quantity",
  "可用": "available",
  "最大": "maximum",
  "排行榜": "Rankings",
  "个人销售": "Personal sales",
  "直推销售": "Direct sales",
  "排名": "ranking",
  "地址": "address",
  "持有": "hold",
  "业绩": "achievement",
  "友情链接": "Friendly Links",
  "暂无记录": "There are currently no records available",
  "没有更多了": "There's no more",
  "我的地址": "My address",
  "级别": "level",
  "首页": "home page",
  "国库兑换": "Treasury exchange",
  "国库USDT记录": "Treasury USDT records",
  "国库LBD铸造": "National Treasury LBD Casting",
  "哈希竞猜": "Hash guessing",
  "我的资产": "My assets",
  "账单记录": "Billing records",
  "我的收益": "My Benefits",
  "我的社区": "My Community",
  "邀请好友": "Invite friends",
  "国库": "national treasury",
  "国库共识USDT总量": "Total Treasury Consensus USDT",
  "国库共识铸币流通量": "Treasury Consensus Coinage Circulation",
  "累计销毁总量": "Accumulated total destruction amount",
  "铸币流通占比": "Currency circulation ratio",
  "LBD待发行量": "LBD to be issued",
  "LBD流通总量": "Total circulation of LBD",
  "LBD销毁总量": "Total LBD destruction amount",
  "铸币": "Coinage",
  "铸币记录": "Coinage Record",
  "请输入": "Please enter",
  "交易": "transaction",
  "币价": "Currency value",
  "金额": "money",
  "实际获得": "Actual acquisition",
  "回流国库": "Returning to the national treasury",
  "闪兑": "Exchange",
  "国库USDT剩余总量": "Total remaining USDT of the national treasury",
  "查看详情": "View Details",
  "国库LBD剩余总量": "Total remaining amount of national treasury LBD",
  "国库U池余额": "Balance of National Treasury U Pool",
  "变动明细": "Change details",
  "价格": "price",
  "时间": "time",
  "铸币池余额": "Mint Pool Balance",
  "铸币明细": "Coinage Details",
  "铸币数量": "Number of Coins",
  "铸币价格": "Coinage price",
  "铸币时间": "Coinage time",
  "竞猜记录": "Record",
  "猜小": "Guess Little",
  "猜大": "Guess Big",
  "猜单": "Guess Single",
  "猜双": "Guess Double",
  "竞猜金额": "Guessing amount",
  "请输入竞猜金额": "Please enter the guessing amount",
  "最近竞猜结果": "Recent Guess Results",
  "确定": "confirm",
  "取消": "cancel",
  "我的竞猜": "My Guess Contest",
  "竞猜": "guessing competition",
  "竞猜结果": "Guessing Results",
  "获得": "obtain",
  "我的账户": "My account",
  "币种": "currency",
  "铸造": "casting",
  "社区收益": "Team Benefits",
  "推荐收益": "Recommended revenue",
  "静态收益": "Static returns",
  "平级收益": "Level return",
  
  '算力释放':'Release of computing power',
  '直推加速':'Direct acceleration',
  '团队加速':'Team acceleration',
  '燃烧静态收益':'Combustion static benefits',
  '燃烧社区':'Burning Community',
  'LP分红':'LP dividends',
  '燃烧直推奖':'Burning Direct Push Award',

  "奖励明细": "Reward Details",
  "当前我的等级": "My current level",
  "社区节点": "Community nodes",
  "有效节点": "Valid nodes",
  "社区业绩": "Community performance",
  "大区业绩": "Regional performance",
  "小区业绩": "Community performance",
  "我的套餐": "My package",
  "总认购": "Total subscription",
  "总收益": "Total revenue",
  "我的直推": "My direct push",
  "序号": "Serial number",
  "注册时间": "Registration time",
  "长按保存图片": "Long press to save image",
  "我的邀请地址": "My invitation address",
  "复制地址": "Copy Address",
  "复制成功": "Successfully copied",
  "返回": "return",
  "等级": "grade",
  "有效人数": "Effective number of people",
  "团队人数": "Number of team members",
  "存入数量": "Deposit quantity",
  "请输入数量": "Please enter the quantity",
  "钱包余额": "Wallet balance",
  "全部": "whole",
  "交易广播成功": "Transaction broadcast successful",
  "钱包余额不足": "Insufficient wallet balance",
  "正在交易中...": "Trading in progress",
  "提取币种": "Extract currency",
  "提取地址": "Extract Address",
  "提取数量": "Extraction quantity",
  "账户余额": "Account balance",
  "手续费": "Handling fees",
  "提交": "Submit",
  "转账币种": "Transfer currency",
  "转账地址": "Transfer address",
  "请输入地址": "Please enter the address",
  "转账数量": "Transfer quantity",
  "静态释放": "Static release",
  "比例": "proportion",
  "动态加速释放": "Dynamic acceleration release",
  "出局倍率": "Exit rate",
  "撤单": "kill an order",
  "提示": "prompt",
  "激活账号": "Activate account",
  "请输入邀请码进行授权激活": "Please enter the invitation code for authorization activation",
  "请输入邀请码（选填）": "Please enter invitation code (optional)",
  "注册": "register",
  "获取地址错误": "Error in obtaining address",
  "认购中...": "Subscription in progress",
  "复购中...": "Re purchasing",
  "闪兑中...": "Flash exchange in progress",
  "铸币中...": "Coinage",
  "竞猜下注中...": "Betting and guessing",
  "加载中...": "Loading",
  "提现中...": "Withdrawing",
  "撤单中...": "Cancelling",
  "映射中...": "Converting",
  "转账中...": "Transferring",
  "确定要认购吗?": "Are you sure you want to subscribe?",
  "确定要复购吗?": "Are you sure you want to repurchase?",
  "确定要闪兑吗?": "Are you sure you want to flash cash?",
  "确定要铸币吗?": "Are you sure you want to coin?",
  "确定要竞猜吗?": "Are you sure you want to compete?",
  "确定要提现吗?": "Are you sure you want to withdraw?",
  "确定要映射吗?": "Are you sure you want to convert?",
  "确定要转账吗?": "Are you sure you want to transfer money?",
  "请选择货币": "Please select a currency",
  "未回本撤出合约，只返回55%USDT，": "Withdrawal of contract without returning principal, only returning 55% USDT,",
  "确定撤出吗?": "Are you sure to withdraw?",
  "销毁": "Destruction",
  "销毁记录": "Destroy records",
  "类型": "type",
  "未注册": "unregistered",
  "账号已被冻结！": "The account has been frozen!",
  "Token异常，登入失敗": "Token exception, login failed",
  "登录成功": "Login successful",
  "参数错误": "Parameter error",
  "请勿重复提交": "Please do not resubmit",
  "钱包地址已存在": "Wallet address already exists",
  "推荐人不存在": "The recommender does not exist",
  "注册成功": "login was successful",
  "注册失败": "login has failed",
  "注册异常": "Registration exception",
  "找不到LBD数据": "Unable to find LBD data",
  "获取成功": "Successfully obtained",
  "获取饼图数据异常": "Exception in obtaining pie chart data",
  "获取公开铸币信息列表异常": "Exception in obtaining public coin information list",
  "用户信息异常": "Abnormal user information",
  "钱包信息错误": "Wallet information error",
  "通证参数不存在": "The token parameter does not exist",
  "获取兑换相关信息异常": "Exception in obtaining redemption related information",
  "获取K线数据异常": "Exception in obtaining K-line data",
  "获取排行榜数据异常": "Exception in obtaining leaderboard data",
  "查询成功": "query was successful",
  "查询个人钱包异常": "Exception in querying personal wallet",
  "最低100，且100的倍数起投": "Minimum of 100, with multiples of 100 starting to operate",
  "区块签名错误": "Block signature error",
  "区块地址错误": "Block address error",
  "找不到投资设置": "Unable to find investment settings",
  "当前无法进行投资,请联系管理员": "Currently unable to make investments, please contact the administrator",
  "用户钱包异常": "Abnormal user wallet",
  "投资异常": "Investment anomaly",
  "获取投资选项异常": "Abnormal acquisition of investment options",
  "文章不存在": "Article does not exist",
  "查询文章异常": "Query article exception",
  "兑换计算结果异常": "Abnormal exchange calculation result",
  "交易数量错误": "Transaction quantity error",
  "暂时无法进行交易,请联系管理员": "Unable to proceed with transaction at the moment. Please contact the administrator",
  "交易数量太少": "Too few transactions",
  "交易成功": "Successful transaction",
  "交易异常": "Abnormal transaction",
  "金额错误": "Amount error",
  "铸币成功": "Coinage Success",
  "铸币异常": "Coinage anomaly",
  "查询幻灯片异常": "Query Slide Exception",
  "查询公告异常": "Abnormal query announcement",
  "请选择玩法": "Please choose a gameplay",
  "竞猜设置异常": "Abnormal guessing settings",
  "当前没有可参与的竞猜": "There are currently no available quizzes to participate in",
  "当前竞猜已停止下注": "The current quiz has stopped betting",
  "下注成功": "Successfully wagered",
  "竞猜下注异常": "Abnormal betting in guessing",
  "获取用户竞猜订单列表异常": "Exception in obtaining user guessing order list",
  "获取用户竞猜列表异常": "Exception in obtaining user guessing list",
  "查询新闻异常": "Abnormal news query",
  "新闻不存在": "News does not exist",
  "查询单条新闻异常": "Exception in querying a single news item",
  "获取我的社区页面数据异常": "Exception in obtaining data for my community page",
  "获取我的社区列表异常": "Exception in obtaining my community list",
  "查询用户信息异常": "Abnormal query of user information",
  "获取账单异常": "Exception in obtaining bills",
  "查询奖金来源异常": "Abnormal query of bonus source",
  "区块链参数异常": "Blockchain parameter exception",
  "查询可映射货币异常": "Query convertible currency exception",
  "查询可提现货币异常": "Abnormal query of withdrawable currency",
  "用户获取投资列表异常": "Abnormal user acquisition of investment list",
  "投资不存在": "Investment does not exist",
  "投资已出局": "Investment has been eliminated",
  "投资已撤单": "Investment has been cancelled",
  "投资状态异常": "Abnormal investment status",
  "当前投资无法撤单": "Current investment cannot be cancelled",
  "撤单成功": "Cancellation successful",
  "撤单异常": "Cancellation exception",
  "获取我的资产页面数据异常": "Exception in obtaining data from my asset page",
  "映射": "conversion",
  "映射币种": "Convert Currency",
  "映射数量": "Conversion quantity",
  "确实": "really",
  "转": "turn",
  "系统繁忙,请稍后再试": "The system is busy, please try again later",
  "货币不存在": "Currency does not exist",
  "转款人钱包错误": "Transferor wallet error",
  "收款人不存在": "The payee does not exist",
  "收款人钱包错误": "Payee wallet error",
  "该货币只能转给团队人员": "This currency can only be transferred to team members",
  "转账成功": "Transfer successful",
  "转账异常": "Abnormal transfer",
  "查询可转账货币异常": "Abnormal query of transferable currency",
  "铸币消耗USD": "Coinage consumption USD",
  "铸币LBD数量": "Number of LBD coins",
  "提取": "extract",
  "立即铸币": "Coinage immediately",
  "单双走势": "Single Double Trend",
  "大小走势": "Size trend",
  "单": "Single",
  "双": "Double",
  "大": "Big",
  "小": "Small",
  "待开奖": "To be awarded",
  "已开奖": "Drawn",
  "区块高度": "Block",
  "涨幅": "Increase",
  "哈希佣金": "Hash Commission",
  "首页滚动公告": "LIBERAL DEAL is a decentralized autonomous organization (DAO) officially launched by Coin Eco, aiming to build an international DAO organization with long-term influence, gather high-level cognitive partners, and empower the world's top blockchain ecological construction with the influence of the community. The total issuance of Library deal (LBD) tokens is 3100w, with deflation reaching 310000. The value of LBD currency in circulation is anchored by the BNB price through the national treasury decentralization agreement. After accumulating 50000 addresses of traffic, Pancake will be launched for transactions, which will be circulated in the LBD ecological application created.",
  "玩法规则": "Gameplay rules",
  "为": "by",
  "玩法规则内容": "Guess the last digit of the hash value of the Coin An block using USDT for betting. If the last digit is a letter, push forward until you find the number, and always use the numbers 0-9 as the lottery result. The lottery will be automatically opened every 3 minutes, with a countdown of 30 seconds for each period. Betting is prohibited, and a return of 1.94 times USD will be given if the bet is successful.",

  '提取记录': 'Extract Records',
  '提现金额': 'Withdrawal amount',
  '交易哈希': 'Transaction Hash',
  '支付': 'Payment',
  '已授权': 'Authorized',
  '授权金额': 'Authorization',
  '授权金额不足，请先授权': 'Insufficient authorization amount, please authorize first',
  '链商': 'Chain-Mall',
  '算力': 'Computing power',
  '待发放': 'To be issued',
  '已发放': 'Issued',
  '区块hash': 'Hash',
  '区块结果': 'Lottery',
  '倒计时': 'Countdown',
  '支付方式': 'Payment method',
  '竞猜销毁记录': 'Destroy records',
  '销毁量': 'Destruction quantity',
  '授权中...': 'Authorizing',
  '授权成功': 'Authorization successful',
  '充值记录': 'Recharge Record',
  '*无邀请码可直接注册！': '*No invitation code required for direct registration!',
  '映射记录': 'Mapping Records',
  '确定要销毁吗？': 'Are you sure you want to destroy?',
  '销毁中': 'Destroying',
  '销毁时间': 'Destruction Time',
  '销毁LBD': 'Destroy LBD',
  '收益金额': 'Earnings Amount',
  '出局金额': 'Outgoing Amount',
  '每日收益': 'Daily Earnings',
  '状态': 'Status',
  '开始时间': 'Start Time',
  '结束时间': 'End Time',
  '待生效': 'Pending',
  '收益中': 'Earning',
  '已出局': 'Exited',
  '已退款': 'Refunded',
  'LBD流量燃烧铸币合约': 'LBD Burns Contracts',
  '燃烧': 'Burning',
  '燃烧记录': 'Burning Record',
  '燃烧LBD': 'Burning LBD',
  '燃烧时间': 'Burning time',
  '待燃烧': 'To be burned',
  '已燃烧': 'Burned',
  'LBD移除映射': 'LBD Remove Mapping',
  '分红记录': 'Dividend records',
  '移除流动性': 'Remove liquidity',
  'USD': 'USD',
  'USDT': 'USDT',
  'LBD': 'LBD',
  'WLBD': 'WLBD',
  'LP': 'LP',
  '七星引流': 'Seven star drainage',
  '铸造中...': 'Casting in progress...',
  '立即铸造': 'Cast immediately'
}
