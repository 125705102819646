import { HubConnectionBuilder } from '@microsoft/signalr'
import config from './config'
let send_url = `${config.send_url}MsgHub`
const connection = new HubConnectionBuilder()
    .withUrl(send_url, {
        withCredentials: false, // 设置为 false，表示不携带凭证
    })
    .withAutomaticReconnect()
    .build();

connection.onclose(() => {
    console.log("连接已关闭")
})
//尝试连接到服务端
function StartConnection(userid) {
    connection.start().then(() => {
        //连接服务器成功后,尝试将连接唯一ID和账号绑定在一起
        connection.invoke("ConnectLogin", userid)

        //监听绑定事件
        connection.on("ConnectLogin", (val) => {
            console.log(val)
        })
    }).catch((error) => {
        console.error('发送消息错误:', error);
    })
}

export default {
    connection,
    StartConnection
}
