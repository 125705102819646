var send_url;
var ws_url;
var Project = "20240711dapp";
if (process.env.NODE_ENV === "development") {
	//开发环境
	send_url = "https://localhost:44350/";
	ws_url = "ws://localhost:";
} else if (process.env.NODE_ENV === "production") {
	//生产环境
	send_url = "https://www.bnbaoburningecology.com/";
	ws_url = "ws://www.bnbaoburningecology.com/wss";
}

export default {
	send_url,
	ws_url,
	Project
};
