<template>
  <div class="empty">
    <img src="@/assets/img/base/empty.png" />
    <div class="empty-text" v-if="text">{{ text }}</div>
    <div class="empty-text" v-else>{{$t('暂无记录')}}</div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    text: {
      type: String,
      default: null
    }
  },
  components: {},
  setup() {
    return {}
  }
}
</script>

<style scoped>
.empty {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.empty img {
  width: 80px;
}

.empty-text {
  margin-top: 6px;
  color: rgb(161, 142, 225);
}
</style>