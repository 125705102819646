<template>
  <div class="nav-bar">
    <div class="nav-bar-row">
      <q-icon name="arrow_back_ios" :color="iconColor" size="16px" @click="onback"></q-icon>
      <div class="title">{{ title }}</div>
      <div><slot name="right"></slot></div>
    </div>
  </div>

  <div class="placeholder"></div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    autoBack: {
      type: Boolean,
      default: true
    },
    iconColor: {
      type: String,
      default: 'white'
    }
  },
  setup() {
    return {

    }
  },
  methods: {
    onback() {
      if(this.autoBack) {
        this.$router.back()
      }
      this.$emit('onBack')
    }
  }
}
</script>

<style scoped>
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}

.nav-bar-row {
  position: relative;
  padding: 0 15px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(93.5deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-size: 16px;
}

.placeholder {
  height: 44px;
}
</style>