module.exports = {
  '您有提现正在确认中,请勿重复提交':'출금이 확인 중입니다. 반복해서 제출하지 마십시오.',
  '提现成功，等待区块确认':'현금 인출 성공, 블록 확인 대기',
  '竞猜下注统计到指定用户':'퀴즈 베팅이 지정된 사용자에게 집계됨',
  '哈希竞猜退款':'해시 퀴즈 환불',
  '第':'제',
  '期':'기',
  '未中奖':'취소됨',
  '已中奖':'당첨',
  '已撤单':'취소됨',
  '撤单申请已提交':'취소 신청이 제출되었습니다.',
  '投资已撤单或正在撤单':'투자 취소 또는 취소 중',
  '认购成功':'구매 성공',
  '复购成功':'재구매 성공',
  '不在下注范围':'베팅 범위에 포함되지 않음',
  '当前无法参与竞猜,请联系管理员':'현재 퀴즈에 참여할 수 없습니다. 관리자에게 문의하십시오.',
  '请重新输入':'다시 입력하십시오.',
  '铸币数量太少':'주화 수량이 너무 적다',
  '不足':'부족',
  '之间的金额':'사이의 금액',
  '不在投资范围':'투자 범위를 벗어나다',
  '国库共识待发行量':'국고 공감대 발행 대기량',
  '强制卖出':'강제 매도',
  '哈希竞猜中奖':'해시 퀴즈 당첨.',
  '用户主动撤单':'사용자 사전 취소',  
  '投资USDT':'USDT 투자',
  '收益':'이익',
  '充值':'충전',
  '提现':'현금 인출',
  '兑换':'환전',
  '增减':'증감',
  '消费':'소비',
  '退款':'환불',
  '总计':'합계',
  '上拿':'올리다',
  '直推':'직추',
  '间推':'간추',
  '新闻公告': '보도 자료',
  '详情': '상세 정보',
  '邀请': '초대',
  '存入': '예치',
  '资产': '자산',
  '转账': '이체',
  '当前价格': '현재 가격',
  '立即': '즉시',
  '认购': '인수',
  '复购': '재구매',
  '消耗': '소모',
  '认购记录': '청약 기록',
  '出局倍数': '아웃 배수',
  '倍': '배',
  '释放比例': '방출 비율',
  '数量': '수량',
  '可用': '사용 가능',
  '最大': '최대',
  '排行榜': '순위',
  '个人销售': '개인 판매',
  '直推销售': '직추 판매',
  '排名': '순위',
  '地址': '주소',
  '持有': '보유',
  '业绩': '업적',
  '友情链接': '우정 링크',
  '暂无记录': '기록 없음',
  '没有更多了': '더 이상 없어요.',
  '我的地址': '내 주소',
  '级别': '수준',
  '首页': '첫 페이지',
  '国库兑换': '국고 환전',
  '国库USDT记录': '국고 USDT 레코드',
  '国库LBD铸造': '국고 LBD 주조',
  '哈希竞猜': '해시 퀴즈',
  '我的资产': '내 자산',
  '账单记录': '청구 기록',
  '我的收益': '내 이익',
  '我的社区': '내 커뮤니티',
  '邀请好友': '친구 초대',
  '国库': '국고',
  '国库共识USDT总量': '국고 합의 USDT 총량',
  '国库共识铸币流通量': '국고 공감대 주화 유통량',
  '累计销毁总量': '총 폐기 누적',
  '铸币流通占比': '주화 유통 점유율',
  'LBD待发行量': 'LBD 발행 예정',
  'LBD流通总量': 'LBD 유통 총량',
  'LBD销毁总量': '총 LBD 제거',
  '铸币': '주화',
  '铸币记录': '주화 기록',
  '请输入': '입력하십시오.',
  '交易': '거래',
  '币价': '화폐 가격',
  '金额': '금액',
  '实际获得': '실제 획득',
  '回流国库': '국고로 환류하다.',
  '闪兑': '환전',
  '国库USDT剩余总量': '국고 USDT 잉여 총량',
  '查看详情': '자세히 보기',
  '国库LBD剩余总量': '국고 LBD 잉여 총량',
  '国库U池余额': '국고 U 풀 잔액',
  '变动明细': '변동 내역',
  '价格': '가격',
  '时间': '시간',
  '铸币池余额': '코인 풀 잔액',
  '铸币明细': '주화 명세',
  '铸币数量': '주화 수량',
  '铸币价格': '주화 가격',
  '铸币时间': '주화 시간',
  '竞猜记录': '퀴즈 기록',
  '猜小': '작게 추측하다',
  '猜大': '추측',
  '猜单': '추측서',
  '猜双': '맞히다',
  '竞猜金额': '퀴즈 금액',
  '请输入竞猜金额': '퀴즈 금액을 입력하세요.',
  '最近竞猜结果': '최근 퀴즈 결과',
  '确定': '확인',
  '取消': '취소',
  '我的竞猜': '내 퀴즈',
  '竞猜': '퀴즈',
  '竞猜结果': '퀴즈 결과',
  '获得': '획득',
  '我的账户': '내 계정',
  '币种': '화폐 종류',
  '铸造': '캐스트',
  '社区收益': '팀 이익',
  '推荐收益': '추천 이익',
  '静态收益': '정적 이익',
  '平级收益': '동급 이익',
  
  '算力释放':'계산력 방출',
  '直推加速':'직추 가속',
  '团队加速':'팀 가속',
  '燃烧静态收益':'연소 정적 이익',
  '燃烧社区':'연소 커뮤니티',
  'LP分红':'LP 배당금',
  '燃烧直推奖':'연소직추상',

  '奖励明细': '보상 내역',
  '当前我的等级': '현재 내 레벨',
  '社区节点': '커뮤니티 노드',
  '有效节点': '유효한 노드',
  '社区业绩': '커뮤니티 실적',
  '大区业绩': '대구역 실적',
  '小区业绩': '아파트 실적',
  '我的套餐': '내 정식',
  '总认购': '총청약',
  '总收益': '총 이익',
  '我的直推': '내 직추',
  '序号': '일련번호',
  '注册时间': '등록 시간',
  '长按保存图片': '그림을 저장하려면 길게 누르십시오.',
  '我的邀请地址': '내 초대 주소',
  '复制地址': '주소 복사',
  '复制成功': '복제 성공',
  '返回': '반환',
  '等级': '등급',
  '有效人数': '유효 인원',
  '团队人数': '팀 수',
  '存入数量': '입금 수량',
  '请输入数量': '수량을 입력하십시오.',
  '钱包余额': '지갑 잔액',
  '全部': '모두',
  '交易广播成功': '거래 방송 성공',
  '钱包余额不足': '지갑 잔액 부족',
  '正在交易中...': '거래 중...',
  '提取币种': '화폐 종류를 인출하다.',
  '提取地址': '주소 추출',
  '提取数量': '추출 수량',
  '账户余额': '계좌 잔액',
  '手续费': '수수료',
  '提交': '제출',
  '转账币种': '이체 화폐',
  '转账地址': '계좌이체 주소',
  '请输入地址': '주소를 입력하십시오.',
  '转账数量': '이체 수량',
  '静态释放': '정적 방출',
  '比例': '비율',
  '动态加速释放': '동적 가속 릴리스',
  '出局倍率': '아웃 배율',
  '撤单': '주문 취소',
  '提示': '힌트',
  '激活账号': '계정 활성화',
  '请输入邀请码进行授权激活': '인증 활성화를 위해 초대 코드를 입력하십시오.',
  '请输入邀请码（选填）': '초대 코드를 입력하십시오 (선택 사항)',
  '注册': '등록',
  '获取地址错误': '주소 가져오기 오류',
  '认购中...': '청약 중...',
  '复购中...': '재구매 중...',
  '闪兑中...': '환전 중...',
  '铸币中...': '주화 중...',
  '竞猜下注中...': '퀴즈 내기 중...',
  '加载中...': '로드 중...',
  '提现中...': '현금 인출 중...',
  '撤单中...': '주문 취소 중...',
  '映射中...': '변환 중...',
  '转账中...': '이체 중...',
  '确定要认购吗?': '청약하시겠습니까?',
  '确定要复购吗?': '재구매하시겠습니까?',
  '确定要闪兑吗?': '환전 하시겠습니까?',
  '确定要铸币吗?': '주화를 만드시겠습니까?',
  '确定要竞猜吗?': '퀴즈 하시겠습니까?',
  '确定要提现吗?': '현금인출 확실해요?',
  '确定要映射吗?': '변환하시겠습니까?',
  '确定要转账吗?': '이체하시겠습니까?',
  '请选择货币': '화폐를 선택하세요',
  '未回本撤出合约，只返回55%USDT，': '본 계약에 대한 반품 없이 55% USDT만 반환,',
  '确定撤出吗?': '철수하시겠습니까?',
  '销毁': '제거',
  '销毁记录': '레코드 제거',
  '类型': '유형',
  '未注册': '미등록',
  '账号已被冻结！': '계정이 이미 동결되었습니다!',
  'Token异常，登入失敗': 'Token 예외, 로그인 실패',
  '登录成功': '로그인 성공',
  '参数错误': '매개변수 오류',
  '请勿重复提交': '다시 제출하지 마십시오.',
  '钱包地址已存在': '지갑 주소가 이미 있습니다.',
  '推荐人不存在': '추천인이 존재하지 않습니다.',
  '注册成功': '등록 성공',
  '注册失败': '등록 실패',
  '注册异常': '등록 예외',
  '找不到LBD数据': 'LBD 데이터를 찾을 수 없음',
  '获取成功': '성공',
  '获取饼图数据异常': '파이 차트 데이터 예외 가져오기',
  '获取公开铸币信息列表异常': '공개 주화 정보 가져오기 목록 예외',
  '用户信息异常': '사용자 정보 예외',
  '钱包信息错误': '지갑 정보 오류',
  '通证参数不存在': '패스스루 매개 변수가 없습니다.',
  '获取兑换相关信息异常': '환전 관련 정보 획득 예외',
  '获取K线数据异常': 'K 라인 데이터 예외 가져오기',
  '获取排行榜数据异常': '차트 데이터 가져오기 예외',
  '查询成功': '질의 성공',
  '查询个人钱包异常': '개인 지갑 이상 조회',
  '最低100，且100的倍数起投': '최소 100 및 100 배수 시작',
  '区块签名错误': '청크 서명 오류',
  '区块地址错误': '청크 주소 오류',
  '找不到投资设置': '투자 설정을 찾을 수 없음',
  '当前无法进行投资,请联系管理员': '현재 투자할 수 없습니다. 관리자에게 문의하십시오.',
  '用户钱包异常': '사용자 지갑 예외',
  '投资异常': '투자 이상',
  '获取投资选项异常': '이상 투자 옵션 가져오기',
  '文章不存在': '글이 존재하지 않음',
  '查询文章异常': '질의 기사 예외',
  '兑换计算结果异常': '환전 계산 결과 예외',
  '交易数量错误': '거래 수량 오류',
  '暂时无法进行交易,请联系管理员': '잠시 거래를 진행할 수 없습니다. 관리자에게 문의하십시오.',
  '交易数量太少': '거래 수량이 너무 적다.',
  '交易成功': '거래 성공',
  '交易异常': '거래 예외',
  '金额错误': '금액 오류',
  '铸币成功': '주화 성공',
  '铸币异常': '주화 이상',
  '查询幻灯片异常': '질의 슬라이드 예외',
  '查询公告异常': '질의 공지 예외',
  '请选择玩法': '게임 방법을 선택하세요',
  '竞猜设置异常': '퀴즈 설정 예외',
  '当前没有可参与的竞猜': '현재 참여할 수 있는 퀴즈가 없습니다.',
  '当前竞猜已停止下注': '현재 퀴즈는 이미 베팅이 중지되었습니다.',
  '下注成功': '베팅 성공',
  '竞猜下注异常': '퀴즈 배팅 예외',
  '获取用户竞猜订单列表异常': '사용자 퀴즈 주문 목록 예외 가져오기',
  '获取用户竞猜列表异常': '사용자 퀴즈 목록 예외 가져오기',
  '查询新闻异常': '뉴스 예외 조회',
  '新闻不存在': '뉴스가 존재하지 않습니다.',
  '查询单条新闻异常': '단일 뉴스 예외 조회',
  '获取我的社区页面数据异常': '내 커뮤니티 페이지 데이터 가져오기 예외',
  '获取我的社区列表异常': '내 커뮤니티 목록 예외 가져오기',
  '查询用户信息异常': '질의 사용자 정보 예외',
  '获取账单异常': '청구 예외 가져오기',
  '查询奖金来源异常': '보너스 출처 예외 조회',
  '区块链参数异常': '블록체인 매개 변수 이상',
  '查询可映射货币异常': '변환 가능한 통화 예외 조회',
  '查询可提现货币异常': '현금인출 가능한 화폐 이상 조회',
  '用户获取投资列表异常': '사용자가 투자 목록을 가져오는 예외',
  '投资不存在': '투자 없음',
  '投资已出局': '투자 아웃됨',
  '投资已撤单': '투자 철회됨',
  '投资状态异常': '투자 상태 예외',
  '当前投资无法撤单': '현재 투자는 청구할 수 없습니다.',
  '撤单成功': '주문 취소 성공',
  '撤单异常': '주문 취소 예외',
  '获取我的资产页面数据异常': '내 자산 가져오기 페이지 데이터 예외',
  '映射': '변환',
  '映射币种': '화폐의 종류를 바꾸다',
  '映射数量': '변환 수량',
  '确实': '확실히',
  '转': '회전',
  '系统繁忙,请稍后再试': '시스템이 사용 중입니다. 나중에 다시 시도하십시오.',
  '货币不存在': '화폐가 존재하지 않는다',
  '转款人钱包错误': '이체자 지갑 오류',
  '收款人不存在': '수취인 없음',
  '收款人钱包错误': '수취인 지갑 오류',
  '该货币只能转给团队人员': '이 통화는 팀원에게만 전달됩니다.',
  '转账成功': '이체 성공',
  '转账异常': '이체 이상',
  '查询可转账货币异常': '이체 가능한 화폐 이상 조회',
  '铸币消耗USD': '주화 소모 USD',
  '铸币LBD数量': '주화 LBD 수량',
  '提取': '추출',
  '立即铸币': '즉시 주화',
  '单双走势': '단쌍추세',
  '大小走势': '크기 추세',
  '单': '단일',
  '双': '더블',
  '大': '큰',
  '小': '작은',
  '待开奖': '대기 중',
  '已开奖': '당첨',
  '区块高度': '청크',
  '涨幅': '상승폭',
  '哈希佣金': '해시 커미션',
  '首页滚动公告': 'LIBERAL DEAL은 코인 생태가 공식적으로 출시한 탈중심화 자치기구 (DAO 조직) 는 장기적인 영향력을 가진 국제 DAO 조직을 건설하고, 고위층 인식의 파트너를 결집하며, 지역사회의 영향력으로 세계 최고의 블록체인 생태 건설을 부여하기 위한 것이다.Liberal deal (LBD) 토큰은 총 3100w 개를 발행하여 31만 위안까지 극히 축소되었으며, LBD 유통 코인의 양적 가치는 국고 탈중심화 협의를 통해 BNB 가격을 고정시켰다.누적 5만 주소 트래픽을 달성한 후 Pancake를 오픈하여 거래하며, 구축된 LBD 생태 애플리케이션에서 유통될 것이다.',
  '玩法规则': '게임 규칙',
  '为': '다음',
  '玩法规则内容': '코인 블록 해시 값의 마지막 숫자 퀴즈는 USDT로 베팅한다. 만약 마지막 사람이 알파벳이면 앞으로 밀어서 숫자를 찾을 때까지 시종 숫자 0~9를 추첨 결과로 한다. 3분마다 자동으로 추첨한다. 매회 30초씩 카운트다운하면 베팅이 금지된다. 1.94배 USD를 맞힌다.',

  '提取记录': '레코드 추출',
  '提现金额': '현금 인출 금액',
  '交易哈希': '거래 해시',
  '支付': '지불',
  '已授权': '승인됨',
  '授权金额': '승인 금액',
  '授权金额不足，请先授权': '권한 부여 금액이 부족합니다. 먼저 권한 부여 하세요',
  '链商': '상점',
  '算力': '계산력',
  '待发放': '지급 대기',
  '已发放': '발급됨',
  '区块hash': '망치다',
  '区块结果': '추첨',
  '倒计时': '카운트다운',
  '支付方式': '지불 방식',
  '竞猜销毁记录': '퀴즈 기록 삭제',
  '销毁量': '폐기량',
  '授权中...': '승인 중',
  '授权成功': '인증 성공',
  '充值记录': '충전 기록',
  '*无邀请码可直接注册！': '*초대 코드 없이 직접 등록할 수 있습니다!',
  '映射记录': '매핑 레코드',
  '确定要销毁吗？': '파괴하시겠습니까?',
  '销毁中': '파괴 중',
  '销毁时间': '파괴 시간',
  '销毁LBD': 'LBD 파괴',
  '收益金额': '수익 금액',
  '出局金额': '퇴출 금액',
  '每日收益': '일일 수익',
  '状态': '상태',
  '开始时间': '시작 시간',
  '结束时间': '종료 시간',
  '待生效': '대기 중',
  '收益中': '수익 중',
  '已出局': '이탈함',
  '已退款': '환불됨',
  'LBD流量燃烧铸币合约': 'LBD 연소 계약',
  '燃烧': '연소',
  '燃烧记录': '연소 기록',
  '燃烧LBD': '연소 LBD',
  '燃烧时间': '연소 시간',
  '待燃烧': '미연소',
  '已燃烧': '연소됨',
  'LBD移除映射': 'LBD 제거 매핑',
  '分红记录': '배당금 기록',
  '移除流动性': '유동성 제거',
  'USD': 'USD',
  'USDT': 'USDT',
  'LBD': 'LBD',
  'WLBD': 'WLBD',
  'LP': 'LP',
  '七星引流': '칠성 인류',
  '铸造中...': '주조 중...',
  '立即铸造': '지금 캐스팅s'
}